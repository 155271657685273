define('siteFeatures', ['jquery', 'scriptOnComplete', 'expander', 'popup'], function($, scriptOnComplete) {
  
  var SiteSpecificFeatures = function() {

    var self = this;
    self._defaultExpanderConfig = {
      slicePoint: 450,
      preserveWords: true,
      widow: 4,
      expandEffect: 'fadeIn',
      expandSpeed: 400,
      collapseEffect: 'fadeOut',
      collapseSpeed: 400,
      collapseTimer: 0,
      expandPrefix: ''
    };

    self.tooltipPopups = function() {

      if ($().popover) {
        $('.lbl-help').popover({
          trigger: 'manual'
        }).hover(function() { //trigger the popover manually to allow us to set a timer to keep it open
          $('.lbl-help').popover('hide'); // hide all open popups
          $(this).popover('show'); //show current one
        }).mouseleave(function() {
          setTimeout('$(\'.popover\').hide()', 2000); //hide popup after 2 seconds
        });
      }
    };

    self.readMore = function(siteObjType) {

      var $body = $('body');
      // Read more expand text for department & list pages
      if ($body.is('.dept, .list, .product') && $('div').hasClass('expand-text')) {

        $('.expand-text p, .expand-text span').css('visibility', 'visible'); // To stop the jerky
        // motion when the
        // page is loaded
        $('.expand-text').expander(self._defaultExpanderConfig);

        // opens product page description when clicking read more on quickview.

        if (siteObjType === 'product' && window.location.hash === '#productOverview') {
          var $readMore = $('.product-info .read-more a');
          $readMore.click();
        }
      }
    };

    self.productReviewScroll = function() {

      var $reviewStars = $('.has-reviews .link');
      var $customerReviews = $('#customer-reviews');

      // Enables page scrolling to an id on
      $reviewStars.click(function() {
        $('html, body').animate({
          scrollTop: $customerReviews.offset().top - 20
        }, 750);
      });
    };


    self.initIsIPad = function() {

      var isiPad = navigator.userAgent.toLowerCase().indexOf('ipad');

      if (isiPad > -1) {
        $('.ipad-hover a').each(function() {
          var onClick;
          var firstClick = function() {
            onClick = secondClick;
            return false;
          };
          var secondClick = function() {
            onClick = firstClick;
            return true;
          };
          onClick = firstClick;
          $(this).click(function() {
            return onClick();
          });
        });
      }
    };

    self.initSupportsTouch = function() {

      var $body = $('body');
      var supportsTouch = 'ontouchstart' in window;

      // touch devices
      if (supportsTouch) {
        $body.addClass('supports-touch');
      }
    };

    self.init = function() {
      self.tooltipPopups();
      self.productReviewScroll();
      self.initIsIPad();
      self.initSupportsTouch();
      scriptOnComplete.write(self._name);
    };
    self._name = 'siteFeatures';
  };

  var sFeatures = new SiteSpecificFeatures();
  sFeatures.init();
  return sFeatures;
});
